import React from "react";
import Layout from "../components/layout/Layout";
import { SEO } from "../components/Seo";
import seoData from "../data/seoData.json";

const NotFound = () => (
  <Layout>
    <div className="h-[calc(99vh-7.5rem)] flex justify-center">
      <div className="flex flex-col items-center m-auto">
        {/* :TITLE */}
        <div className="text-center space-y-5">
          <p className="text-6xl sm:text-7xl text-primary-default font-bold tracking-wide">
            404
          </p>
          <h1 className="text-3xl sm:text-4xl md:text-5xl text-primary-darkest font-semibold capitalize">
            PÁGINA NO ENCONTRADA
          </h1>
          <p className="text-sm text-primary-darkest font-medium">
            La página que buscabas no existe o no la hemos podido encontrar.
          </p>
        </div>

        {/* :OPTION LINKS */}
        <div className="mt-8 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
          <a
            href="https://whypeople.es/"
            className="px-5 py-2.5 rounded border border-transparent bg-primary-default text-center text-base text-white font-medium hover:bg-primary-darker hover:text-white"
          >
            VOLVER A HOME
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFound;

export const Head = () => (
  <SEO>
    <script type="application/ld+json">{JSON.stringify(seoData)}</script>
  </SEO>
);
